import React, { Component } from 'react';
import pizza from '../200w.gif';
import arrow from '../arrow.png';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                { name: 'NexusTimer', link: 'https://github.com/bryanlundberg/NexusTimer', description: 'Professional SpeedCubing Timer', tech: 'TypeScript, Tailwind CSS, Open Source' },
                { name: 'Bl0ckify', link: 'https://devpost.com/software/digitize-eth', description: 'NFT Marketplace, Hack the North Winner', tech: 'TypeScript, React, CockroachDB, Node.js, ThirdWeb' },
                { name: 'GreenPlates', link: 'https://devpost.com/software/greenplates?ref_content=my-projects-tab&ref_feature=my_projects', description: 'DeltaHacks AI/ML App', tech: 'Node.js, OpenAI API, React' },
                { name: 'Pupil7', link: 'https://www.pupil7.com/', description: 'EdTech Platform with 1000+ users', tech: 'Next.js, Node.js, MongoDB, Google Cloud Platform' },
                { name: 'VitalWave', link: 'https://github.com/idrak888/vitalwave', description: 'OEC 2023 AI/ML Healthcare App', tech: 'JavaScript, React, Express, Gulp, Machine Learning' },
                { name: 'Remix', link: 'https://devpost.com/software/remix-mobile-app-for-discovering-smoothie-recipes', description: 'Smoothie Recipes, McHacks', tech: 'JavaScript, React Native, Node.js, MongoDB' },
                { name: 'TherapyAI', link: 'https://devpost.com/software/therapyai-roxh25', description: 'UofTHacks AI project', tech: 'Cohere API, React, Node.js, NLP' },
                { name: 'Tickerblue', link: 'https://tickeroid.web.app/analysis', description: 'Stock Market Screener', tech: 'React, Node.js, Express, Yahoo Finance API, NLP' },
                { name: 'Lab2Client', link: 'https://lab2client.com/', description: 'Researcher Marketplace', tech: 'Next.js, Stripe, Node.js, Firebase' }
            ]
        };
    }
    render() {
        return (
            <div className="Home">
                <div className='container'>
                    <div className='inner'>
                        <div className='col'>
                            <img src={pizza}/>
                            <br/>
                            <h3>about me</h3>
                            <p>Hello! I'm Idrak, a CS student and aspiring Software Engineer based in Toronto, ON. </p>

                            <p>I am currently pursuing a degree in Computer Science (B.Sc) at York University, expected to graduate in 2026.</p>

                            <h3>📂 projects</h3>
                            <ul>
                                {this.state.projects.map((project, index) => {
                                    return (
                                        <li key={index}>
                                            <img src={arrow} width={30}/> <a href={project.link}>{project.name}</a>: {project.description} 
                                            <p>{project.tech}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className='links'><a href="https://medium.com/@idrakislam">medium</a> | <a href='https://github.com/idrak888'>github</a> | <a href='https://www.linkedin.com/in/idrak-islam/'>linkedin</a></div>
                </div>
            </div>
        );
    }
}

export default Home;